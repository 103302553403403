$primary-color: #007bff;
$card-bg: linear-gradient(135deg, #0d0f1a, #08142e);
$text-color: #ffffff;
$secondary-text: #a0a0a0;
$head-card-bg: linear-gradient(135deg, #0a0f1c 0%, #1a1f2c 100%);
$head-card-border: 1px solid rgba(255, 255, 255, 0.1);

.team-scroll-container {
    width: 100%;
    overflow-x: hidden;
    padding: 20px 0;
    display: flex;
    position: relative;
    justify-content: center;

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.team-scroll-wrapper {
    display: flex;
    gap: 2rem;
    padding: 0 20px;
    scroll-behavior: smooth;
    min-width: 200%;

}

.team-card-wrapper {
    flex: 0 0 auto;
    width: 18rem;
}

.team-card {
    position: relative;
    width: 100%;
    background: $card-bg;
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    color: $text-color;
    border: 1px solid rgba(255, 255, 255, 0.1);

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.4);
    }

    .team-image-container {
        position: relative;
        width: 150px;
        height: 150px;
        margin: 20px auto;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }

        .social-icons {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            opacity: 0;
            transition: opacity 0.3s ease;
            border-radius: 50%;

            a {
                color: white;
                font-size: 20px;
                transition: color 0.3s ease;

                &:hover {
                    color: $primary-color;
                }
            }
        }

        &:hover .social-icons {
            opacity: 1;
        }
    }

    .card-body {
        padding: 1.5rem;
        text-align: center;
    }

    .team-name {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        color: $text-color;
        font-weight: 500;
    }

    .team-role {
        font-size: 0.9rem;
        color: $secondary-text;
        margin-bottom: 0;
    }


}

@media (max-width: 768px) {
    .team-scroll-container {
        padding: 10px 0;
    }

    .team-scroll-wrapper {
        flex-wrap: nowrap;
        display: flex;
        gap: 1rem;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-padding: 10px;
        -webkit-overflow-scrolling: touch;
        min-width: 100%;
    }

    .team-card-wrapper {
        flex: 0 0 auto;
        width: 14rem;
        scroll-snap-align: start;
    }

    .team-card {
        width: 100%;
        height: 90%;
        padding: 1rem;
    }

    .team-image-container {
        width: 100px;
        height: 100px;
    }

    .social-icons {
        opacity: 0;
        transition: opacity 0.3s ease;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 50%;
    }

    .team-image-container:hover .social-icons {
        opacity: 1;
    }

    .social-icons a {
        font-size: 18px;
    }

    .team-name {
        font-size: 1rem;
    }

    .team-role {
        font-size: 0.8rem;
    }
}