.custom-navbar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  max-width: 100vw;
  padding: 15px 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease-in-out;
  z-index: 1000;

  .logo img {
    height: 40px;
    padding-left: 30px;
  }

  .logo .logo-name {
    color: #fff;
  }

  .nav-link {
    color: white;
    font-size: 16px;
    margin: 0 15px;
    transition: 0.3s;

    &:hover {
      color: #007bff !important;
    }
  }
}

.custom-navbar .navbar-toggler {
  border-color: white !important;
}

.custom-navbar .navbar-toggler-icon {
  color: #fff !important;
}

.custom-navbar {
  transition: transform 0.3s ease-in-out;

  &.navbar-hidden {
    transform: translateY(-100%);
  }

  &.animate__animated {
    animation-duration: 0.6s;
  }
}

.animate__fadeInDown {
  animation: fadeInDown 0.6s ease-in-out;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.detail-page-navbar {
  background-color: rgba(255, 255, 255, 0.1);
  height: 75px;

  .nav-link {
    color: #000 !important;
  }
}
