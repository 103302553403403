.glassy-image {
    background: rgba(255, 255, 255, 0.1); 
    border-radius: 15px;
    backdrop-filter: blur(12px); 
    -webkit-backdrop-filter: blur(12px); 
    border: 1px solid rgba(255, 255, 255, 0.3); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); 
    padding: 5px; 
    max-width: 100%; 
    height: auto; 
    transition: transform 0.3s ease-in-out; 
  }
  .glassy-image:hover {
    transform: scale(1.05);
  }
  