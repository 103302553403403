.footer-container {
    background: linear-gradient(135deg, #0a0f1c 0%, #1a1f2c 100%);
    min-height: 100vh;
}

.footer-logo {
    max-width: 80px;
    height: auto; 
    filter: brightness(0) invert(1); 
}


.footer-about {
    p {
        font-size: 1.1rem;
        color: #ffffff;

        &.text-secondary {
            color: #b5bdc4;
        }
    }
}

.footer-title {
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 500;
}

.footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    li {
        a {
            color: #6c757d;
            text-decoration: none;
            font-size: 1rem;
            transition: color 0.3s ease;

            &:hover {
                color: #ffffff;
            }

            .social-icon {
                font-size: 1.2rem; 
                color: white;
                margin-right: 8px; 
            }
        }
    }
}

.subscription-container {
    max-width: 400px;
}

.footer-input {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
    padding: 0.375rem 0.75rem;
    border-radius: 8px;

    &:focus {
        background: rgba(255, 255, 255, 0.08);
        border-color: rgba(255, 255, 255, 0.2);
        box-shadow: none;
        color: #ffffff;
    }

    &::placeholder {
        color: #6c757d;
    }
}

.subscribe-btn {
    background: #0066ff;
    border: none;
    padding: 0.375rem 0.75rem;
    border-radius: 8px;
    font-weight: 500;
    transition: background-color 0.3s ease;

    &:hover {
        background: #0052cc;
    }

    &:focus {
        box-shadow: none;
    }
}

.copyright {
   color: #ffffff; 
   font-size: 0.9rem; 
   margin-top: 20px; 
}

@media (max-width: 991px) {
   .subscription-container {
       max-width: 100%;
   }

   .footer-links {
       margin-bottom: 2rem;
   }
}

@media (max-width: 576px) {
   Form {
       flex-direction: column;

       .subscribe-btn {
           width: 50%;
       }
   }
}
