.responsive-video {
    width: 100%;
    height: 200px;

    @media (min-width: 768px) {
        width: 700px;
        height: 400px;
    }

    @media (min-width: 1024px) {
        width: 900px;
        height: 500px;
    }
}
